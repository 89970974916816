body {
  height: 100vh;
  margin: 0;
  flex-grow: 0;
  font-family: "Roboto", "Noto Sans TC", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/* remove input arrow */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

pre {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", "Noto Sans TC", sans-serif;
}
